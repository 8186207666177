.forgot-p{
    text-align: center;
    margin-top: 20px;
}
.reset-p{
    margin-top: 20px;
}
.go-back{
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
    font-weight: 600;
}
.qr-div {
    text-align: center;
    margin-top: 20px;
    font-size: 50px;
}

.google-signin > div {
    width: 100% !important;
    .nsm7Bb-HzV7m-LgbsSe-Bz112c{
        margin-left: 105px;
    }
    .nsm7Bb-HzV7m-LgbsSe-BPrWId{
        text-align: left;
        margin-left: 5px;
    }
}
.google-signin {
    width: 366px !important;
    .nsm7Bb-HzV7m-LgbsSe{
        width: 355px !important;
    }
    iframe{
        width: 100% !important;
    }
}
.haAclf{
    width: 100% !important;
}
.nsm7Bb-HzV7m-LgbsSe {
    max-width: 410px !important;
}

.signin-button{
    width: 355px;
}


.office365-signin{
    
    button{
        height: 40px;
        background-color: white;
        border: 1px solid #dadce0;
        width: 355px;
        span{
            font-weight: 500;
            font-size: 14px;
            font-family: "Google Sans",arial,sans-serif;
        }
    }
}

.subscriber{
    padding: 10px;
    border: 1px solid #dadce0;
    margin-bottom: 6px;
    text-align: center;
    cursor: pointer;
}

.signin-input{
    width: 350px;
}
.authentication-box{
    width: 405px;
}